import React from 'react';

const logo = () => (
	<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 240 240" className="logo">
		<path fill="#fff" d="M191.751,74.653l-15.158,8.751l-15.296,8.831l-13.276,7.665V21.68v-9.619V1.377L130.44,11.529l-3.619,2.089l-17.582,30.454
			L88.103,80.679L72.631,65.207l-9.224-9.224l-3.647-3.647l-13.935,8.046v2.884v17.418v78.221v20.302l17.582-10.151v-20.302V80.848
			l15.595,15.595l12.871,12.871l9.101-15.764l29.466-51.036v67.537v12.964l-8.219-8.219l-5.989,3.458l-9.775,5.643l-7.808,4.508
			v20.302v89.914l17.582-10.151v-89.914v-4.884l3.096,3.096l10.307,10.307l12.871,12.871l9.101-15.764l22.561-39.077l2.425-1.4v65.462
			v24.452l15.502-8.95l2.081-1.201V93.556V73.254L191.751,74.653z M143.715,122.689l-4.979,8.623l-3.645-3.645L143.715,122.689z"/>
	</svg>
);

export default logo;

