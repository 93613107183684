import React, { Component, Suspense } from 'react';
import Logo from './logo';
import Menu from './menu';
const LazyPlayer = React.lazy(() => import('./player'));

class Layout extends Component {

  constructor() {
    super();
    this.state = {
      showMenu: false
    }
  }

  toggleMenu = () => {
    this.setState({ showMenu: !this.state.showMenu });
  }

  render() {
    return(
      <div className="App">
        <div className="wrapper anim-logo"> 
          <header className="App-header">
            <Logo />
            <button className="menu-btn" onClick={this.toggleMenu}>
              { this.state.showMenu ? 'close' : 'menu' }
            </button>
            { this.state.showMenu ? 
              <Menu hide={this.toggleMenu}/> : null }
          </header>
          <main>
            { this.props.children }
          </main>
          <Suspense fallback={<span>loading player</span>}>
            <LazyPlayer shouldPause={this.props.shouldPausePlayer}/>
          </Suspense>
        </div>        
      </div>
      
    )
  }
}

export default Layout;