import React, { Component } from 'react';
import { BrowserRouter as Router, Route, Switch } from 'react-router-dom';
import Layout from './components/layout';
import Stage from './components/stage';
import Shows from './components/shows';
import About from './components/about';
import Video from './components/video';
//import SpotifyPlayer from 'react-spotify-player';
import './App.css';

class App extends Component {

  constructor() {
    super();
    this.state = {
      shouldPausePlayer: false
    }
  }

  pausePlayer = () => {
    this.setState({ shouldPausePlayer: true }, () => {
      this.setState({ shouldPausePlayer: false })
    })
  }

  render() {
    return (
      <Router>
        <Layout shouldPausePlayer={this.state.shouldPausePlayer}>         
          <Switch>
            <Route exact path="/" component={Stage}/>
            <Route path="/shows" component={Shows}/>
            <Route path="/about" component={About}/>
            <Route path="/video" render={props => <Video {...props} pausePlayer={this.pausePlayer}/>}/>
          </Switch>
        </Layout>
      </Router> 
    );
  }
}

export default App;
