import React, { Component } from 'react';
import { Link } from 'react-router-dom'; 
import YouTube from 'react-youtube';

class Video extends Component {

  handleStateChange = (e) => {
    if(e.target.getPlayerState() === 1) {
      this.props.pausePlayer();
    }
  }

  render() {
    const iWidth = window.innerWidth;
    const vWidth = iWidth < 992 ? iWidth : iWidth - ((iWidth * 30) / 100);
    const vHeight = (1080 / 1920) * vWidth;
    return(
      <div className="overlay" style={{ height: window.innerHeight }}>
        <Link to="/" className="back-btn">home</Link>
        <YouTube
          videoId="izM96FQ0VYo"
          opts={{
            height: vHeight,
            width: vWidth,
            playerVars: { // https://developers.google.com/youtube/player_parameters
              autoplay: 0
            }
          }}
          onStateChange={this.handleStateChange}
        />
      </div>
    )
  }
}

export default Video;