import React, { Component } from 'react';
import { Link } from 'react-router-dom';

class Shows extends Component {

  constructor() {
    super();
    this.state = {
      gigs: [
        { date: '06.04.2019', address: 'Unter den Linden 23\n72762 Reutlingen', venue: 'Franz.K', link: 'https://www.franzk.net/events/view/id/173792/date/1554501600' }
      ]
    }
  }

  render() {
    return(
      <div className="overlay" style={{ height: window.innerHeight }}>
        <Link to="/" className="back-btn">home</Link>
        <div>
          { this.state.gigs.map((gig, i) => (
            <div key={i} style={{ color: 'white' }}>
              <span>{gig.date}</span>
              <span>{gig.venue}</span>
              <span>{gig.address}</span>
              { gig.link ? <a target="_blank" rel="noopener noreferrer" href={gig.link}>more</a> : null }
            </div>
          ))}
        </div>
      </div>
    )
  }
}

export default Shows;