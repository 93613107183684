import React, { Component } from 'react';
import { Link, withRouter } from 'react-router-dom'; 

class Menu extends Component {

  constructor() {
    super();
    this.state = {
      items: [
        { name: 'shows' },
        { name: 'about' },
        { name: 'video' }
      ]
    }
  }

  componentDidMount() {
    document.addEventListener('click', this.outsideClick, false);
  }

  componentWillUnmount() {
    document.removeEventListener('click', this.outsideClick, false);
  }

  outsideClick = (e) => {
    if(this.node.contains(e.target)) {
      return;
    }
    this.props.hide();
  }

  render () {
    return (
      <div className="menu" ref={ node =>{ this.node = node }}>
        <nav>
          <ul>
            { this.state.items.map((item, i) => (
              <li key={i}><Link to={`/${item.name}`} className={ this.props.location.pathname === `/${item.name}` ? 'current' : '' }>{ item.name }</Link></li>
            ))}
          </ul>
        </nav>
      </div>
    )
  }
}

export default withRouter(Menu);