import React, { Component } from 'react';
import imgAleksi from '../assets/aleksi.jpg';
import imgAleksi768 from '../assets/aleksi768.jpg';
import imgAleksi480 from '../assets/aleksi480.jpg';
import imgPauline from '../assets/pauline.jpg';
import imgPauline768 from '../assets/pauline768.jpg';
import imgPauline480 from '../assets/pauline480.jpg';
import imgAndrea from '../assets/andrea.jpg';
import imgAndrea768 from '../assets/andrea768.jpg';
import imgAndrea480 from '../assets/andrea480.jpg';
import imgAlejandro from '../assets/alejandro.jpg';
import imgAlejandro768 from '../assets/alejandro768.jpg';
import imgAlejandro480 from '../assets/alejandro480.jpg';

class Stage extends Component {

  constructor() {
    super();
    this.state = {
      members: [
        { name: 'Alejandro', pic: { w960: imgAlejandro, w768: imgAlejandro768, w480: imgAlejandro480 } },
        { name: 'Pauline', pic: { w960: imgPauline, w768: imgPauline768, w480: imgPauline480 } },
        { name: 'Andrea', pic: { w960: imgAndrea, w768: imgAndrea768, w480: imgAndrea480 } },
        { name: 'Aleksi', pic: { w960: imgAleksi, w768: imgAleksi768, w480: imgAleksi480 } }
      ]
    }
  }

  render (){
    return (
      <div className="stage" style={{ maxHeight: window.innerHeight }}>
        {this.state.members.map((member, i) => (
          <div key={i} style={{ height: window.innerWidth > 992 ? window.innerHeight : '25%' }}>
            <span>{member.name}</span>
            <picture>
              <source sizes="100vw" srcSet={`${member.pic.w480} 480w, ${member.pic.w768} 768w`} />
              <img src={member.pic.w960} alt={member.name} />
            </picture>
          </div>
        ))}
      </div>
    )
  }
}

export default Stage;