import React, { Component, Suspense } from 'react';
import { Link } from 'react-router-dom';
const LazyViewer = React.lazy(() => import('./imgViewer'));

class About extends Component {

  render() {
    const iw = window.innerWidth < 992 ? window.innerWidth - ((window.innerWidth * 10) / 100) : (window.innerWidth - ((window.innerWidth * 10) / 100)) / 2;
    const w = iw < 640 ? iw : 640 ;
    return(
      <div className="overlay" style={{ height: window.innerHeight }}>
        <Link to="/" className="back-btn">home</Link>
        <div>
          <div className="text-wrap">
            <h2>ManufacturaMusica</h2>
            <p> Two guitarists a wonderful voice and a DJ. </p> 
            <p> We've been called independent, </p> 
            <p> though we'd prefer diverse for we come from all corners of the world: </p> 
            <p> Finnland, Italy, Germany and Colombia. </p>
            <a href="mailto:booking@manufacturamusica.com">booking@manufacturamusica.com</a>
          </div>
          <div className="viewer-wrap" ref={ node => { this.node = node } } style={{ width: w, height: (800 / 1200) * w }}>
            <Suspense fallback={<span>loading image viewer</span>}>   
              <LazyViewer w={w} />       
            </Suspense>
          </div>
        </div>
      </div>
    )
  }
}

export default About;